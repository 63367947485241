import React, {useEffect, useState} from "react";
import {Button, Collapse, Divider, Form, Input, notification, Spin, Switch, Typography,} from "antd";
import {get, put} from "../../../api/axios";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {ArrowLeftOutlined} from "@ant-design/icons";
import UnitsComponent from "./helper/UnitsComponent"; // Your Units component
import QuestionsComponent from "./helper/QuestionsComponent"; // Your Questions component
import ImagesUploadComponent from "../../control-sections/helper/ImagesUploadComponent";
import MapComponent from "../../control-sections/helper/MapComponent";
import DocumentUploadComponent from "../../control-sections/helper/DocumentUploadComponent"; // Your Google Map component

const { Title } = Typography;
const {Panel} = Collapse;

const EditAgentProject = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [fromLoading, setFromLoading] = useState(false);
  const [inputsValue, setInputsValue] = useState({});
  const [fileArList, setFileArList] = useState([]);
  const [fileEnList, setFileEnList] = useState([]);
  const [fileUnits, setFileUnits] = useState([]); 
  const [fileQuestions, setFileQuestions] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    get(`agent-projects/${id}`).then((res) => {
      setInputsValue(res.data);
      if (res.data.latitude && res.data.longitude) {
        setMarkerPosition({
          lat: Number(res.data.latitude),
          lng: Number(res.data.longitude)
        });
      }
      setFileArList(res.data.file_ar ? [{
        url: res.data.file_ar.path,
        path: res.data.file_ar.file,
        name: res.data.file_ar.file.substring(res.data.file_ar.file.lastIndexOf('/') + 1)
      }] : []);
      setFileEnList(res.data.file_en ? [{
        url: res.data.file_en.path,
        path: res.data.file_en.file,
        name: res.data.file_en.file.substring(res.data.file_en.file.lastIndexOf('/') + 1)
      }] : []);
      setFileUnits(res.data.file_units ? [{
        url: res.data.file_units.path,
        path: res.data.file_units.file,
        name: res.data.file_units.file.substring(res.data.file_units.file.lastIndexOf('/') + 1)
      }] : []);
      setFileQuestions(res.data.file_questions ? [{
        url: res.data.file_questions.path,
        path: res.data.file_questions.file,
        name: res.data.file_questions.file.substring(res.data.file_questions.file.lastIndexOf('/') + 1)
      }] : []);
      setImageList(res.data.photos.map(
            (photo) => ({
              url: photo.path,
              path: photo.file
            })
      ));
      setFromLoading(true);
    });

  }, []);
  const handleMarkerPositionChange = (position) => {
    setMarkerPosition(position);
    console.log("Updated Marker Position:", position);
  };
  const handleSwitch = (status, name) => {
    setInputsValue({...inputsValue, [name]: status});
  };
  const handleDocumentUpload = (filePath, fileName) => {
    setInputsValue((prev) => ({ ...prev, [fileName]: filePath }));
  };
  const handleDeleteFile = (filePath, fileName) => {
    // Remove the file path from inputsValue
    setInputsValue((prev) => ({ ...prev, [fileName]: null }));
  };
  const handleImageUpload = (imageUrl) => {
    console.log("Image URL:", imageUrl);
    setImageList((prev) => [...prev, imageUrl]);
  };
  const onFinish = (values) => {
    setLoading(true);
    if (!inputsValue.file_ar) {
      notification.error({
        message: 'Missing required file',
        description: 'Please upload Arabic file.',
      });
      setLoading(false);
      return;
    }
    if (!inputsValue.file_en) {
      notification.error({
        message: 'Missing required file',
        description: 'Please upload English file.',
      });
      setLoading(false);
      return;
    }
    if (!imageList.length) {
      notification.error({
        message: 'Missing required images',
        description: 'Please upload at least one image.',
      });
      setLoading(false);
      return;
    }
    console.log(imageList);
    let data = {
      ...inputsValue,
      ...values,
      file_en: inputsValue.file_en?.file || inputsValue.file_en || null,
      file_ar: inputsValue.file_ar?.file || inputsValue.file_ar || null,
      file_units: inputsValue.file_units?.file || inputsValue.file_units || null,
      file_questions: inputsValue.file_questions?.file || inputsValue.file_questions || null,

      is_active: inputsValue.is_active ? 1 : 0,
      is_sold_out: inputsValue.is_sold_out ? 1 : 0,
      photos: imageList.map((image) => (image.path || image.url)),
      latitude: markerPosition?.lat || inputsValue.latitude,
      longitude: markerPosition?.lng || inputsValue.longitude,
    };

     put(`agent-projects/${id}`, data)
        .then(() => {
          notification.success({ message: "Modified successfully" });
          setLoading(false);
          navigate("/agent-projects");
        })
        .catch((err) => {
          notification.error({
            message: "Error!",
            description: err.response.data.message,
          });
          setLoading(false);
        });
  };

  return (
      <div>
        <Title level={2} className="text-main mb-2">
          <ArrowLeftOutlined onClick={() => navigate("/agent-projects")}/>
          Edit Agent Project
        </Title>

        {fromLoading ? (
            <Form
                className="wrapper-content"
                onFinish={onFinish}
                labelCol={{span: 7}}
                wrapperCol={{span: 15}}
                initialValues={{
                  name_ar: inputsValue.name_ar,
                  name_en: inputsValue.name_en,
                  is_active: inputsValue.is_active,
                  is_sold_out: inputsValue.is_sold_out,
                  developer_en: inputsValue.developer_en,
                  developer_ar: inputsValue.developer_ar,
                  location_ar: inputsValue.location_ar,
                  location_en: inputsValue.location_en,
                  price: inputsValue.price,
                  order: inputsValue.order,
                  latitude: inputsValue.latitude,
                  longitude: inputsValue.longitude,
                  units: inputsValue.units || [],
                  questions: inputsValue.questions || [],
                  
                }}
                autoComplete="off"
            >
              <div className="row">
                <Collapse defaultActiveKey={['1']}>
                  <Panel header="General Info" key="1">
                    <div className="row">
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Name Ar"
                            name="name_ar"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                        >
                          <Input placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Name En "
                            name="name_en"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                        >
                          <Input placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Developer Ar"
                            name="developer_ar"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                        >
                          <Input placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Developer En "
                            name="developer_en"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                        >
                          <Input placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Location Ar"
                            name="location_ar"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                        >
                          <Input placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Location En "
                            name="location_en"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              },
                            ]}
                        >
                          <Input placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Price"
                            name="price"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              }
                            ]}
                        >
                          <Input type="number"
                                 placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="Order"
                            name="order"
                            rules={[
                              {
                                required: true,
                                message: "This field is required!",
                              }
                            ]}
                        >
                          <Input type="number"
                                 placeholder="Type..."/>
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="File Ar"
                            name="file_ar"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "This field is required!",
                            //   }
                            // ]}
                        >
                          <DocumentUploadComponent
                              onFileUpload={(file) => handleDocumentUpload(file, 'file_ar')}
                              onFileDelete={(file) => handleDeleteFile(file, 'file_ar')}
                              fileList={fileArList}
                              setFileList={setFileArList}
                              storagePath={'agent-projects'}
                          />
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item
                            label="File En"
                            name="file_en"
                        >
                          <DocumentUploadComponent
                              onFileUpload={(file) => handleDocumentUpload(file, 'file_en')}
                              onFileDelete={(file) => handleDeleteFile(file, 'file_en')}
                              fileList={fileEnList}
                              setFileList={setFileEnList}
                              storagePath={'agent-projects'}
                          />
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item label="Active " name="is_active">
                          <Switch defaultChecked={inputsValue.is_active}
                                  onChange={(status) => handleSwitch(status, "is_active")}
                          />
                        </Form.Item>
                      </div>
                      {/* Item */}
                      <div className="col-lg-6">
                        <Form.Item label="Sold Out " name="is_sold_out">
                          <Switch
                              defaultChecked={false}
                              onChange={(status) => handleSwitch(status, "is_sold_out")}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-lg-12">
                        <ImagesUploadComponent
                            onImageUpload={handleImageUpload}
                            imageList={imageList}
                            setImageList={setImageList}
                            storagePath={'agent-projects'}
                        />
                      </div>
                      <div className="col-lg-12">
                        <MapComponent
                            onMarkerPositionChange={handleMarkerPositionChange}
                            initialPosition={markerPosition}
                        />
                      </div>
                    </div>
                  </Panel>
                  <Divider />
                  <Panel header="Units" key="2">
                    <Title level={4} className="mt-3"> Units </Title>
                    <Form.Item name="file_units" label="Upload Your Units">
            <DocumentUploadComponent
              onFileUpload={(file) => handleDocumentUpload(file, 'file_units')}
              onFileDelete={(file) => handleDeleteFile(file , 'file_units')}
              fileList={fileUnits}
              setFileList={setFileUnits}
              storagePath={"agent-projects/units"}
            />
           </Form.Item>
                    <UnitsComponent/>
                  </Panel>
                  <Divider/>
                  <Panel header="Questions" key="3">
                    <Title level={4} className="mt-3"> Questions </Title>
                    <Form.Item name="file_questions"  label="Upload Your Questions">
            <DocumentUploadComponent
              onFileUpload={(file) => handleDocumentUpload(file, 'file_questions')}
              onFileDelete={(file) => handleDeleteFile(file , 'file_questions')}
              fileList={fileQuestions}
              setFileList={setFileQuestions}
              storagePath={"agent-projects/questions"}
            />
           </Form.Item>
                    <QuestionsComponent/>
                  </Panel>
                </Collapse>
          </div>
          <div className="row mt-5 pt-2">
            <div className="col-lg-4 offset-lg-4">
              <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
              >
                <Button
                    loading={loading}
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                >
                  {loading ? "Updating..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
          <div className="text-center">
            <Spin size="large"/>
          </div>
      )}
    </div>
  );
};

export default EditAgentProject;
